import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankidAuthInit, BankIDCollectStatus, BankIDCompletionData } from '@core/auth/bankid.interface';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { ActionNeededInterface } from '@shared/components/action-needed/action-needed.interface';
import { ActionsService } from '@shared/services/actions.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

export interface LoginContext {
	username?: string;
	usernameEmail?: string;
	password: string;
	remember?: boolean;
}

export interface AuthUser {
	firstname: string;
	partner_login: boolean | false;
	leader_login: boolean | false;
	user_login: boolean | false;
	sessionID: number;
	surname: string;
	token: string;
	user_type: string;
	type_name: string;
	foodPref: string
	accessibilityOptions: any
	disabilities: string
	allergies?: boolean;
	companyID?: number;
	company_size?: string;
	company_type?: string;
	contactperson?: number;
	disabilitydetails?: true;
	userID?: number;
	username?: string;

	contactID?: number;
	email?: string;
	allow_course_edit?: string;
	edit_course_fields?: string;
	activate_notices?: string;

	projectID?: number;
	projects?: { fields: any[], list: any[] };
	approvedTermsFor?: { fields: any[], list: any[] };


	// EA
	PersonId: number
	CustomerId: number
	FirstName: string
	LastName: string
	Address: string
	Address2: string
	Zip: string
	City: string
	Mobile: string
	Phone: string
	Email: string
	CivicRegistrationNumber: string
	Birthdate: string
	EmployeeNumber: string
	JobTitle: string
	Country: string
	CountryCode: string
	SsoId: string
	LmaNumber: string
	Created: string
	Modified: string
	CanLogin: boolean
	IsContactPerson: boolean
	PurchaseOrderNumber: string
	Reference: string
	IndividualId: number
	CustomFields: Array<{
		CustomFieldId: number
		CustomFieldName: string
		CustomFieldType: string
		MetaType: string
		ShowOnWeb: boolean
		SortIndex: any
		CustomFieldValue: string
		CustomFieldChecked: boolean
		CustomFieldDate: any
		CustomFieldAlternativeId: number
		CustomFieldAlternativeValue: string
	}>
	Consents: Array<any>
}

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {


	public currentUser: any;
	public currentCompany: any;
	public currentUserSubject: BehaviorSubject<AuthUser>;
	public appSettings: any;
	public projectSettings: any;

	constructor(private http: HttpClient, private router: Router, private actionsService: ActionsService, public msgService: MessageService, private route: ActivatedRoute) {
		this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentAuth')));
		this.auth = this.currentUserSubject.asObservable();

		if (this.currentAuth) {
			this.currentUser = this.currentAuth['person'];
			this.currentCompany = this.currentAuth['customer'];

			this.appSettings = JSON.parse(localStorage.getItem('app.settings'));
			this.projectSettings = JSON.parse(localStorage.getItem('project.settings'));
		}
	}

	private _bankIDAuthCompletion: BankIDCompletionData;
	private _bankIDAuthInit: BankidAuthInit;
	private _auth: Observable<AuthUser>;

	get bankIDAuthCompletion(): BankIDCompletionData {
		return this._bankIDAuthCompletion;
	}

	set bankIDAuthCompletion(value: BankIDCompletionData) {
		this._bankIDAuthCompletion = value;
	}

	get bankIDAuthInit(): BankidAuthInit {
		return this._bankIDAuthInit;
	}

	set bankIDAuthInit(value: BankidAuthInit) {
		this._bankIDAuthInit = value;
	}

	public get currentAuth(): AuthUser {
		return this.currentUserSubject.value;
	}

	get auth(): Observable<AuthUser> {
		return this._auth;
	}

	set auth(value: Observable<AuthUser>) {
		this._auth = value;
	}

	getRole() {
		return this.currentAuth.user_type;
	}

	isLeader()
	{
		return this.getRole() == 'leader';
	}

	getLeaderType() {
		return this.currentAuth?.type_name;
	}

	getSelectedProject() {
		let get = localStorage.getItem('project.browse');
		return get !== null ? get.toLowerCase() : get;
	}
	setSelectedProject(project: string, reload: boolean = false) {
		localStorage.setItem('project.browse', project);
		if(reload)
			document.location.reload();
	}
	getAvailableProjects() {
		let currentAuth = JSON.parse(localStorage.getItem('currentAuth'));
		return currentAuth?.projects.list;
	}

	isProjectInAvailable(project: string) {
		let available = this.getAvailableProjects();
		return available.findIndex((i: string) => i == project.toLowerCase()) > -1;
	}

	isCourseLeader() {
		return this.currentAuth?.leader_login == true;
	}

	isUser() {
		return this.currentAuth?.user_login == true;
	}

	isSuperAdmin() {
		return this.getRole() == 'superadmin';
	}

	getUserID(): number {
		if (this.currentAuth) {
			return this.currentAuth.userID;
		}
		return 0;
	}

	getCompanyID(): number {
		if (this.auth) {
			return this.currentAuth.companyID;
		}
		return 0;
	}

	/**
	 * Senaste funegrade sättet är att använda actionNeededModal!!
	 */
	needProjectApprove(returnUrl: string | null) {

		let notApproved = this.getNotApprovedProjects();

		//return false;
		if(this.router.url.includes('godk%C3%A4nn-villkor'))
			return false;

		if (notApproved.length > 0) {
			//await this.router.navigate(['/profil/godkänn-villkor']);
			return true;
		}

		return false;
	}

	getNotApprovedProjects()
	{
		let user = this.currentAuth;
		let notApproved = [];

		//console.error('Check getNotApprovedProjects', user);
		let projects = user.projects && user.projects.list ?
			user.projects.list.map((i: string) => i.trim().toLowerCase())
			: [];

		//console.error('Projects: ', projects);
		let approvedFor =
			user.approvedTermsFor && user.approvedTermsFor.list ?
				user.approvedTermsFor.list.map((i: string) => i.trim().toLowerCase())
				: [];
		notApproved = projects.filter((p) => !approvedFor.includes(p));

		//console.error('Has approved for: ', approvedFor);

		//console.error('NOT approved for: ', notApproved);
		if (notApproved.length > 0) {
			//console.error('::::: NEED APPROVEMENT')
		}

		//user.approvedTermsFor.list = user.projects.list;
		//this.currentUserSubject.next(user);
		//localStorage.setItem('currentAuth', JSON.stringify(user));

		return notApproved;
	}

	acceptTerms() {
		let formData = new FormData();
		formData.append('subaction', 'acceptTerms');
		//formData.append('params', JSON.stringify(invite));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	/**
	 * Authenticates the user.
	 * @param context The login parameters.
	 * @return The user credentials.
	 */
	login(context: LoginContext): Promise<any> {
		let formData = new FormData();

		formData.append('action', 'p46_ca_public_login');
		formData.append('p46_login_username', context.username);
		formData.append('p46_login_password', context.password);

		return this.http
			.post<AuthUser>(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <AuthUser>res)
			.then((data: AuthUser) => {
				if (data['error'] == 0 && data['auth'] && data['auth']['token']) {
					data['auth']['user_login'] = true;
					this.auth = data['auth'];

					this.currentUser = data['auth']['person'];
					this.currentCompany = data['auth']['customer'];
					this.appSettings = data['app.settings'];
					this.projectSettings = data['project.settings'];

					this.currentUserSubject.next(data['auth']);

					localStorage.setItem('currentAuth', JSON.stringify(this.auth));
					localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
					localStorage.setItem('currentCompany', JSON.stringify(this.currentCompany));
					localStorage.setItem('app.settings', JSON.stringify(data['app.settings']));
					localStorage.setItem('project.settings', JSON.stringify(data['project.settings']));
					localStorage.setItem('validatedAnalysis', JSON.stringify(data['validatedAnalysis']));

					//this.actionNeededTrigger();
				}
				return data;
			});
	}

	loginSuperadmin(context: LoginContext): Promise<any> {
		let formData = new FormData();

		formData.append('action', 'p46_ca_public_login_superadmin');
		formData.append('p46_login_username', context.username);
		formData.append('p46_login_password', context.password);

		return this.http
			.post<AuthUser>(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <AuthUser>res)
			.then((data: AuthUser) => {
				if (data['error'] == 0 && data['auth'] && data['auth']['token']) {
					data['auth']['user_login'] = true;
					this.auth = data['auth'];

					this.currentUser = data['auth']['person'];
					this.currentCompany = data['auth']['customer'];
					this.appSettings = data['app.settings'];
					this.projectSettings = data['project.settings'];

					this.currentUserSubject.next(data['auth']);

					localStorage.setItem('currentAuth', JSON.stringify(this.auth));
					localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
					localStorage.setItem('currentCompany', JSON.stringify(this.currentCompany));
					localStorage.setItem('app.settings', JSON.stringify(data['app.settings']));
					localStorage.setItem('project.settings', JSON.stringify(data['project.settings']));

					//this.actionNeededTrigger();
				}
				return data;
			});
	}

	/**
	 * Authenticates the user.
	 * @param context The login parameters.
	 * @return The user credentials.
	 */
	loginFromBankID(data:any) {

		if (data['error'] == 0 && data['auth'] && data['auth']['token']) {
			data['auth']['user_login'] = true;
			this.auth = data['auth'];

			this.currentUser = data['auth']['person'];
			this.currentCompany = data['auth']['customer'];
			this.appSettings = data['app.settings'];
			this.projectSettings = data['project.settings'];

			this.currentUserSubject.next(data['auth']);

			localStorage.setItem('currentAuth', JSON.stringify(this.auth));
			localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
			localStorage.setItem('currentCompany', JSON.stringify(this.currentCompany));
			localStorage.setItem('app.settings', JSON.stringify(data['app.settings']));
			localStorage.setItem('project.settings', JSON.stringify(data['project.settings']));
			localStorage.setItem('validatedAnalysis', JSON.stringify(data['validatedAnalysis']));
			localStorage.setItem('app.lastLogin', JSON.stringify(data['_lastLogin']));

			localStorage.removeItem('dd.course.selectedViewType');

			/*Sentry.setUser({
				userID: this.currentUser?.PersonId,
				companyID: this.currentCompany.companyID,
				company: this.currentCompany.companyname
			});*/
			//this.actionNeededTrigger();
		}
	}

	/**
	 * Authenticates the user.
	 * @param context The login parameters.
	 * @return The user credentials.
	 */
	loginLeader(context: LoginContext): Promise<any> {
		let formData = new FormData();

		formData.append('action', 'p46_ca_public_login_leader');
		formData.append('p46_login_leader_username', context.usernameEmail);
		formData.append('p46_login_leader_password', context.password);

		return this.http
			.post<AuthUser>(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <AuthUser>res)
			.then((data: AuthUser) => {
				if (data['error'] == 0 && data['auth'] && data['auth']['token']) {
					data['auth']['leader_login'] = true;
					this.auth = data['auth'];

					this.currentUser = data['getUser'];
					this.appSettings = data['app.settings'];
					this.projectSettings = data['project.settings'];

					console.error(':: Leader Login, contactID: ', this.currentUser?.contactID);

					this.currentUserSubject.next(data['auth']);
					localStorage.setItem('currentAuth', JSON.stringify(this.auth));
					localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
					localStorage.setItem('currentCompany', '{}');
					localStorage.setItem('app.settings', JSON.stringify(data['app.settings']));
					localStorage.setItem('project.settings', JSON.stringify(data['project.settings']));

					Sentry.setUser({
						contactID: this.currentUser?.contactID,
					});
				}
				return data;
			});
	}

	collectBankIDQRCode(): Promise<any> {
		let formData = new FormData();

		formData.append('action', 'collectQRBankID');
		formData.append('orderRef', this.bankIDAuthInit.apiCallResponse.Response.OrderRef);

		return this.http
			.post<BankIDCollectStatus>(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <BankIDCollectStatus>res)
			.then((data: BankIDCollectStatus) => {
				return data;
			});
	}

	poolBankIDStatus(): Promise<any> {
		let formData = new FormData();

		formData.append('action', 'collectStatusBankID');
		formData.append('orderRef', this.bankIDAuthInit.apiCallResponse.Response.OrderRef);

		return this.http
			.post<BankIDCollectStatus>(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <BankIDCollectStatus>res)
			.then((data: BankIDCollectStatus) => {

				if(data['error']) {
					console.error('1');
					if(data['constant'] && data['constant'] == 'userNotFound') {
						this.msgService.add({
							severity:'warn',
							summary: 'Har du accepterat din inbjudan?',
							detail: 'Vi hittar inte ditt personnummer. Innan du kan logga in måste du acceptera inbjudan från din företagsledare som mejlats från noreply@affarskompetens.se',
							life: 1000000
						});
					}
				}
				return data;
			});
	}

	authBankID(includeQR: boolean = false): Promise<any> {
		let formData = new FormData();

		formData.append('action', 'authBankID');
		if(includeQR)
			formData.append('includeQR', '1');

		return this.http
			.post<BankidAuthInit>(environment.serverUrl, formData)
			.toPromise()
			.then((res) => <BankidAuthInit>res)
			.then((data: BankidAuthInit) => {
				return data;
			});
	}

	async validateLeaderAuth(): Promise<any> {
		let formData = new FormData();
		formData.append('subaction', 'validateLeaderToken');

		return await this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				if (data['access'] == false) {
					this.logout();
					return;
				}
				this.currentUser = data['getUser'];
				this.currentCompany = {};
				this.appSettings = data['app.settings'];
				this.projectSettings = data['project.settings'];

				Sentry.setUser({
					contactID: this.currentUser?.contactID,
				});

				//this.currentUserSubject.next(data['getUser']);

				localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
				localStorage.setItem('app.settings', JSON.stringify(data['app.settings']));
				localStorage.setItem('project.settings', JSON.stringify(data['project.settings']));
				localStorage.setItem('currentCompany', JSON.stringify(this.currentCompany));

				return data;
			});
	}

	async validateAuth(): Promise<any> {
		let formData = new FormData();
		formData.append('subaction', 'validateToken');

		return await this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then(async (data) => {
				if (data['access'] == false) {
					this.logout();
					return;
				}

				this.auth = data['auth'];
				this.currentUserSubject.next(data['auth']);

				this.currentUser = data['auth']['person'];
				this.currentCompany = data['auth']['customer'];
				this.appSettings = data['app.settings'];
				this.projectSettings = data['project.settings'];

				localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
				localStorage.setItem('app.settings', JSON.stringify(data['app.settings']));
				localStorage.setItem('project.settings', JSON.stringify(data['project.settings']));
				localStorage.setItem('currentCompany', JSON.stringify(this.currentCompany));

				localStorage.setItem('validatedAnalysis', JSON.stringify(data['validatedAnalysis']));

				/*Sentry.setUser({
					userID: this.currentUser?.PersonId,
					companyID: this.currentCompany.companyID,
					company: this.currentCompany.companyname
				});*/

				/*if (this.getRole() != 'employee') {
					if ((data['payed_fee'] === false && this.projectSettings['activatePayment'] == true) || (data['accountableSet'] === false && this.projectSettings['requireAccountable'] == true)) {
						await this.router.navigate(['/företagswizard']);
						return;
					}
					if (data['payed_fee'] === false && this.projectSettings['activatePayment'] == true) {
						await this.router.navigate(['/företagswizard/betalning']);
						return;
					}
					if (data['accountableSet'] === false && this.projectSettings['requireAccountable']) {
						await this.router.navigate(['/företagswizard/ansvarig']);
						return;
					}
				}*/

				/*console.error('finishedProfile', data, data['finishedProfile'])
				if (data['finishedProfile'] === false) {
					console.error('----------- SHOULD ROUTE TO WIZ');
					await this.router.navigate(['/användarwizard']);
					return;
				}*/
				//const returnUrl: string = this.route.snapshot.url[0].path;
				console.error('Validate auth, disabled route to setting startpage for SUPERADMIN', this.router.url);
				/*this.route.url.subscribe(async (event) => {
					if (await this.needProjectApprove(event[0].path) === true) {
						return;
					}
				});*/
				/*if (await this.needProjectApprove(this.router.url) === true) {
					return;
				}*/

				if(!this.isSuperAdmin() && this.projectSettings['startPage'] != '/hem' &&
					//this.projectSettings['startPage'] != '/superadmin/activities' &&
					!this.router.url.includes('analyser')) {
					console.error('Tryng to reroute to: ', this.projectSettings['startPage']);

					await this.router.navigate([this.projectSettings['startPage']]);
					return;
				}

				return data;
			});
	}

	actionNeededTrigger() {
		// /enkat/11/0 = företagare: företagsledare men bara om företaget har fler anställda
		// /enkat/10/0 = företagsledare i enskild firma och medarbetare oavsett bolagsstorlek

		let validatedAnalysis = <any>JSON.parse(localStorage.getItem('validatedAnalysis') || '');

		console.error(this.router.url);
		if(validatedAnalysis && this.isLeader() && !this.router.url.includes('projektv%C3%A4ljare')) {
			let getAnalyze_14 = validatedAnalysis.find((i: any) => i.analyzeID == 14);

			if(getAnalyze_14 &&
				getAnalyze_14['projects'] && getAnalyze_14['projects'].includes(this.getSelectedProject()) &&
				getAnalyze_14['userTypes'] && getAnalyze_14['userTypes'].includes(this.currentAuth['user_type'])) {
				if (getAnalyze_14['finished'] == false) {
					let action: ActionNeededInterface = {
						buttons: [
							{type: 'close', link: '', title: 'Jag gör det senare', color: 'link'},
							{
								type: 'internal',
								link: '/analyser/visa/14',
								title: 'Jag vill göra den direkt',
								color: 'primary'
							},
						],
						content: `
						<p>Vi vill ge dig möjlighet att göra en kartläggning för att öka din kännedom och medvetenhet kopplat till ditt företags behov av kompetensutveckling.</p>
						<p>Det tar endast ca 5 minuter att göra kartläggningen och du får direkt ett tydligt spindeldiagram över nuläge samt önskat läge. Det kommer hjälpa dig att välja rätt kurser.</p>
					`,
						force: false,
						title: 'Få koll på vilka behov ditt företag har',
						type: 'cover'
					};

					this.actionsService.openActionNeededModal.emit(action);
				}
			}
		}

		// Används istället för att routa till approve-terms comp!
		if(this.needProjectApprove('') === true) {
			let action: ActionNeededInterface = {
				buttons: [
					//{type: 'close', link: '', title: 'Jag gör det senare', color: 'link'},
					//{type: 'internal', link: surveyLink, title: 'Ta mig till enkäten', color: 'secondary'},
				],
				content: 'ApproveTermsNeededComponent',
				force: true,
				title: 'Vi vill ha din hjälp!',
				type: 'cover',
				color: 'red',
				width: '700px'
			};

			this.actionsService.openActionNeededModal.emit(action);
		}

		return;

		if(this.currentUser.validated_zerobase == true || this.projectSettings['activateZerobase'] == false)
			return;

		let surveyLink = '/enkat/10/0';
		if(this.currentAuth['user_type'] != 'employee' && this.currentAuth['company_size'] != "0") {
			surveyLink = '/enkat/11/0';
		} else if(this.currentAuth['user_type'] == 'employee' || this.currentAuth['company_size'] == "0") {
			surveyLink = '/enkat/10/0';
		}

		if(surveyLink != '') {
			let action: ActionNeededInterface = {
				buttons: [
					{type: 'close', link: '', title: 'Jag gör det senare', color: 'link'},
					{type: 'internal', link: surveyLink, title: 'Ta mig till enkäten', color: 'secondary'},
				],
				content: 'Nollbasmätningen är en enkät som vi hoppas att du vill hjälpa oss att besvara. Den handlar om företagets digitala kunskapsnivå innan projektet startade.',
				force: false,
				title: 'Vi vill ha din hjälp!',
				type: 'cover'
			};

			//this.actionsService.openActionNeededModal.emit(action);

		} else
			console.error('SURVEY FEL: kunde inte matcha användare mot enkät', this.currentAuth);
	}

	/**
	 * Logs out the user and clear credentials.
	 * @return True if the user was logged out successfully.
	 */
	logout() {
		localStorage.removeItem('currentUser');
		localStorage.removeItem('currentAuth');
		localStorage.removeItem('currentCompany');
		localStorage.removeItem('finalizedCompanyWizard');
		localStorage.removeItem('finalizedUserWizard');
		localStorage.removeItem('companyWizardCurrentStep');
		localStorage.removeItem('userWizardCurrentStep');
		localStorage.removeItem('app.settings');
		localStorage.removeItem('project.settings');
		localStorage.removeItem('project.browse');

		this.auth = null;
		this.currentUser = null;
		this.currentCompany = null;
		this.appSettings = null;

		this.currentUserSubject.next(null);
		this.actionsService.openActionNeededModal.emit(null);

		this.router.navigate(['/login']);
		/*setTimeout(() => {
			window.location.reload();
		}, 300);*/
	}

}
